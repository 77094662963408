import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useState, useRef } from "react";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import dayjs from 'dayjs';


function NameMapChangeModal({ name, mappedName, onNameMappingChange, onCancel, showModal}) {

  const nameInputRef = useRef();

  function handleSave(){
    console.log("Saving");
    onNameMappingChange(name, nameInputRef.current.value);
  }

  function handleKeyUp(event) {
    if (event.key === 'Enter') {
      handleSave();
    }
  }

  return (
    <Modal show={showModal} onHide={onCancel}>
      <Modal.Header closeButton>
        Neuer Anzeigename für {name}
      </Modal.Header>
      <Modal.Body>
        <input
          autoFocus
          type='text'
          ref={nameInputRef}
          defaultValue={mappedName}
          onFocus={e => e.target.select()}
          onKeyUp={handleKeyUp}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          Abbrechen
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Speichern
        </Button>
      </Modal.Footer>
    </Modal>

  );
}

function NameMapPlate({ name, mappedName, onNameMappingDelete, onNameMappingChange}) {
  
  const [showModal, setShowModal] = useState(false);

  return (
    <div key='name' className='name-map-plate'>
      <NameMapChangeModal 
        name={name}
        mappedName={mappedName}
        onNameMappingChange={(name, mappedName) => {
          setShowModal(false)
          onNameMappingChange(name, mappedName);
        }}
        onCancel={() => {setShowModal(false)}}
        showModal={showModal}
      />
      <div>
        {name} → {mappedName}
      </div>
      <div>
        <span className='inline-badge inline-edit' onClick={() => {setShowModal(true)}}>
          <FontAwesomeIcon size='sm' icon='pen' />
        </span>
        <span
          className='inline-badge inline-delete'
          onClick={() => { onNameMappingDelete(name) }}
        >
          <FontAwesomeIcon size='sm' icon='trash' />
        </span>
      </div>
    </div>
  );
}

function NameSettings({ nameMapping, onNameMappingChange, onNameMappingDelete, onNameMappingDeleteAll}) {
  
    return (
    <div className='main-container'>
      <h2>Namenszuordnung</h2>
      <div className='name-map-plate-container'>
        {Object.keys(nameMapping.manual).map(name => {
          return (
            <NameMapPlate
              name={name}
              mappedName={nameMapping.map(name)}
              onNameMappingDelete={onNameMappingDelete}
              onNameMappingChange={onNameMappingChange}
              key={name}
            />);
        })}
      </div>
      <div className='config-buttons'>
        <Button as='a' 
          className='link-to-file' 
          href={URL.createObjectURL(new Blob([JSON.stringify({manual: nameMapping.manual})]), 'text/json')
          }
          download={`Namenszuordnung-${dayjs().format('YYYY-MM-DD_HH-mm')}.json`}
        >
            <FontAwesomeIcon icon='download'/>
            &nbsp; Namenszuordnung
        </Button>
        &nbsp;
        <Button variant='danger' onClick={onNameMappingDeleteAll}> 
          <FontAwesomeIcon icon='trash-arrow-up'/>
            &nbsp; Alle Zuordnungen löschen
        </Button>
      </div>
    </div>
  );
}


export default NameSettings;
