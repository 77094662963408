import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function TagIcon({icon}) {
    if(icon.type === 'picture') {
      return (
        <span className='picture-icon' style={{'color': icon.color, 'backgroundColor':icon.background}}>
          <FontAwesomeIcon size='sm' icon={icon.icon}/>
        </span>
      );
    } else {
      return (
        <span className='text-icon' style={{'color': icon.color, 'backgroundColor':icon.background}}>{icon.text}</span>
      );
    }
}

export {TagIcon};