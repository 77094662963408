
class NameMapping{
    constructor(manual = {}){
        let local = {}
        
        // try to read name mapping from local storage
        try {
            local = JSON.parse(localStorage.getItem('nameMapping'));
        } catch {
            local = {};
        }
        
        // set local name mapping
        this.manual = Object.assign({}, manual, local);
    }

    map(name) {
        // return manual mapping if it exists
        if (this.manual[name]){
            return this.manual[name]
        }

        const nameClean = name.replaceAll(/[^a-zA-ZÀ-ž\s-]/g, '').replaceAll(/\s+/g, ' ');
        const parts = nameClean.split(' ');
        
        if (parts.length === 2) {
            return parts[1]
        }

        if (parts.length === 3) {
            return parts[1]
        }

        if (parts.length === 4) {
            return parts[2]
        }

        return parts[parts.length-1];
    }

    addMapping(name, mappedName) {
        let manual = JSON.parse(JSON.stringify(this.manual));
        manual[name] = mappedName;
        localStorage.setItem('nameMapping', JSON.stringify(manual));
        return new NameMapping(manual);
    }

    deleteMapping(name) {
        let manual = JSON.parse(JSON.stringify(this.manual));
        delete manual[name];
        localStorage.setItem('nameMapping', JSON.stringify(manual));
        return new NameMapping(manual);
    }

    deleteAll() {
        localStorage.setItem('nameMapping', '{}');
        return new NameMapping({});
    }
}

export default NameMapping;