import { useState, useRef } from 'react';

import { useDrag, useDrop, DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import dayjs from 'dayjs';

import { TagIcon } from './defaultElements';

function IgnoredShiftPlate({ shift, onIgnoredShiftsDelete }) {
    return (
        <div className='ignore-shift-plate'>
            <span>
                {shift}
            </span>
            <span className='inline-badge inline-delete' onClick={() => { onIgnoredShiftsDelete(shift) }}>
                <FontAwesomeIcon size='xs' icon='trash' />
            </span>
        </div>
    );
}

function IgnoredShiftSettings({ ignoredShifts, onIgnoredShiftsAdd, onIgnoredShiftsDelete }) {

    const [showModal, setShowModal] = useState(false);

    const inputRef = useRef(null);

    function handleSaveModal() {
        setShowModal(false);
        onIgnoredShiftsAdd(inputRef.current.value);
    }

    function handleCancelModal() {
        setShowModal(false)
    }

    return (
        <div className='settings-panel'>
            <Modal show={showModal} onHide={handleCancelModal}>
                <Modal.Header closeButton>
                    Schicht ignorieren
                </Modal.Header>
                <Modal.Body>
                    <input
                        ref={inputRef}
                        autoFocus
                        onFocus={e => e.target.select()}
                        onKeyUp={e => { if (e.key === 'Enter') handleSaveModal() }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCancelModal}>
                        Abbrechen
                    </Button>
                    <Button variant="primary" onClick={handleSaveModal}>
                        Speichern
                    </Button>
                </Modal.Footer>
            </Modal>
            <h3>Ignorierte Schichten</h3>
            <div className='ignore-shift-plate-container'>
                {ignoredShifts.map(shift => {
                    return (
                        <IgnoredShiftPlate
                            shift={shift}
                            onIgnoredShiftsDelete={onIgnoredShiftsDelete}
                            key={shift}
                        />);
                })}
            </div>
            <Button
                onClick={() => { setShowModal(true) }}
                className='m-4'
            >
                <FontAwesomeIcon icon='circle-plus' />
                &nbsp;
                Weitere Schicht ignorieren
            </Button>
        </div>
    );

}

function StaffCategoryPlateDrop({ column, row, onMove }) {

    const [{ isOver }, dropRef] = useDrop(
        () => ({
            accept: 'staffCategory',
            drop: (item) => {
                console.log(`Dropped item from (${item.column}, ${item.row}) to (${column}, ${row})`);
                onMove(item, { column, row });
            },
            collect: (monitor) => ({
                isOver: !!monitor.isOver(),
                item: monitor.getItem(),
            })
        })
    )

    return (
        <div
            className={isOver ? 'staff-category-plate-drop-over' : 'staff-category-plate-drop'}
            ref={dropRef}
        >
        </div>
    );
}

function StaffCategoryPlate({ category, column, row, onDelete }) {

    const [{ isDragging }, dragRef] = useDrag(
        () => ({
            type: 'staffCategory',
            item: { column, row },
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging()
            })
        }),
        []
    )

    return (
        <div
            className='staff-category-plate'
            ref={dragRef}
            style={{ opacity: isDragging ? 0.4 : 1 }}
        >
            <div>
                {category}
            </div>
            <div
                className='inline-badge inline-delete'
                onClick={() => onDelete(column, row)}
            >
                <FontAwesomeIcon size='sm' icon='trash' />
            </div>
        </div>
    );
}

function StaffCategoryContainer({ categories, column, onMove, onDelete }) {
    return (
        <div className='staff-category-plate-container'>
            {categories.map((category, n) => {
                return (
                    <div className='staff-category-plate-drop-container' 
                        key={n}
                    >
                        <StaffCategoryPlateDrop
                            row={n}
                            column={column}
                            key={n}
                            onMove={onMove}
                        />
                        <StaffCategoryPlate
                            category={category}
                            row={n}
                            column={column}
                            onDelete={onDelete}
                            key={category}
                        />
                    </div>
                )
            })}
            <StaffCategoryPlateDrop
                row={categories.length}
                key={categories.length}
                column={column}
                onMove={onMove}
            />
        </div>
    );
}

function StaffCategoryAddModal({onCategoryAdd}) {

    const [showModal, setShowModal] = useState(false);
    const categoryRef = useRef();

    function handleCancel() {
        setShowModal(false);
    }

    function handleSave() {
        onCategoryAdd(categoryRef.current.value);
        setShowModal(false);
    }

    return (
        <>
        <Button className='m-4' onClick={() => setShowModal(!showModal)}>
            <FontAwesomeIcon size='sm' icon='user-plus'/>
            <span className='m-3'>Neue Personalkategorie</span>
        </Button>

        <Modal show={showModal} onHide={handleCancel}>
            <Modal.Header closeButton>
                Neue Personalkategorie
            </Modal.Header>
            <Modal.Body>
                <input 
                    autoFocus 
                    name='category'
                    ref={categoryRef}
                    onKeyUp={e => { if (e.key === 'Enter') handleSave() }}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCancel}>
                    Abbrechen
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    Speichern
                </Button>
            </Modal.Footer>
        </Modal>
        </>
    );

}

function StaffCategorySettings({ staffCategories, onSetStaffCategoriesFromArray }) {


    function handleMove(posOld, posNew) {
        let arr = staffCategories;
        if (posOld.column !== posNew.column || posOld.row !== posNew.row) {
            let rowNew;
            if (posOld.column === posNew.column && posNew.row > posOld.row) {
                rowNew = posNew.row - 1;
            } else {
                rowNew = posNew.row;
            }
            const category = arr[posOld.column][posOld.row];
            arr[posOld.column].splice(posOld.row, 1);
            arr[posNew.column].splice(rowNew, 0, category);
            onSetStaffCategoriesFromArray(arr);
        }
    }

    function handleDelete(column, row) {
        let arr = staffCategories;
        arr[column].splice(row, 1);
        onSetStaffCategoriesFromArray(arr);
    }

    function handleCategoryAdd(category) {
        staffCategories[0].push(category);
        onSetStaffCategoriesFromArray(staffCategories);
    }

    const containerHeadings = ['Oben', 'Unten', 'Ignorieren']
    return (
        <div className='settings-panel'>
            <h3>
                Personalkategorien
            </h3>
            <StaffCategoryAddModal onCategoryAdd={handleCategoryAdd}/>
            <div className='staff-category-container'>
                {staffCategories.map((categories, n) => {
                    return (
                        <div 
                            className='staff-category-column'
                            key={`${n}-${JSON.stringify(staffCategories)}`}
                        >
                            <h4>{containerHeadings[n]}</h4>
                            <StaffCategoryContainer
                                column={n}
                                categories={categories}
                                onMove={handleMove}
                                onDelete={handleDelete}
                            />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

function ShiftTrunkAddShiftModal({onShiftAdd}) {

    const [showModal, setShowModal] = useState(false);
    const shiftRef = useRef();

    function handleCancel() {
        setShowModal(false);
    }

    function handleSave() {
        onShiftAdd(shiftRef.current.value);
        setShowModal(false);
    }

    return (
        <>
        <Button className='m-4' onClick={() => setShowModal(!showModal)}>
            <FontAwesomeIcon size='sm' icon='calendar-plus'/>
            <span className='m-3'>Schicht hinzufügen</span>
        </Button>

        <Modal show={showModal} onHide={handleCancel}>
            <Modal.Header closeButton>
                Schicht hinzufügen
            </Modal.Header>
            <Modal.Body>
                <input 
                    autoFocus 
                    name='shift'
                    ref={shiftRef}
                    onKeyUp={e => { if (e.key === 'Enter') handleSave() }}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCancel}>
                    Abbrechen
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    Speichern
                </Button>
            </Modal.Footer>
        </Modal>
        </>
    );

}

function ShiftTrunkAddContainerModal({onTrunkCreate}) {

    const [showModal, setShowModal] = useState(false);
    const containerRef = useRef();

    function handleCancel() {
        setShowModal(false);
    }

    function handleSave() {
        onTrunkCreate(containerRef.current.value)
        setShowModal(false);
    }

    return (
        <>
        <Button className='m-4' onClick={() => setShowModal(!showModal)}>
            <FontAwesomeIcon size='sm' icon='square-plus'/>
            <span className='m-3'>Schichtcontainer hinzufügen</span>
        </Button>

        <Modal show={showModal} onHide={handleCancel}>
            <Modal.Header closeButton>
                Name des neuen Containers
            </Modal.Header>
            <Modal.Body>
                <input 
                    autoFocus 
                    name='container'
                    ref={containerRef}
                    onKeyUp={e => { if (e.key === 'Enter') handleSave() }}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCancel}>
                    Abbrechen
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    Speichern
                </Button>
            </Modal.Footer>
        </Modal>
        </>
    );

}

function ShiftDrop({row, column, onShiftMove}) {
    const [{ isOver }, dropRef] = useDrop(
        () => ({
            accept: 'shift',
            drop: (item) => {
                console.log(`Dropped item from (${item.column}, ${item.row}) to (${column}, ${row})`);
                onShiftMove(item, {row, column});
            },
            collect: (monitor) => ({
                isOver: !!monitor.isOver(),
                item: monitor.getItem(),
            })
        })
    )

    return (
        <div 
            className={isOver?'shift-drop-over':'shift-drop'}
            ref={dropRef}
        >
        </div>
    );
}

function ShiftTrunkShiftPlate({shift, nShift, nTrunk, onShiftDelete}) {

    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: 'shift',
            item: { row: nTrunk, column: nShift},
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging()
            })
        }),
        []
    )


    return (
        <div 
            ref={drag}
            className='shift-trunk-shift-plate' 
            style={{opacity:isDragging?0.4:1}}
        >
            {shift}
            <span 
                className='inline-badge inline-delete'
                onClick={onShiftDelete}
            >
                <FontAwesomeIcon size='xs' icon='trash' />
            </span>            
        </div>
    );
}


function ShiftTrunkShifts({shifts, nTrunk, onShiftsChange, onShiftMove}) {

    function handleShiftDelete(n) {
        shifts.splice(n, 1);
        onShiftsChange(shifts);
    }
    
    return (
        <div className='shift-trunk-shifts-container'>
            {shifts.map( (shift, n) => {
                return (
                    <div
                        key={shift}
                        className='shift-drop-container'
                    >
                        <ShiftDrop 
                            row={nTrunk} 
                            column={n}
                            onShiftMove={onShiftMove}
                        />
                        <ShiftTrunkShiftPlate 
                            shift={shift}
                            nShift={n}
                            nTrunk={nTrunk}
                            onShiftDelete={() => handleShiftDelete(n)}
                        />
                    </div>
                );
            })}
            <div className='shift-drop-container'>
                <ShiftDrop 
                    row={nTrunk} 
                    column={shifts.length}
                    onShiftMove={onShiftMove}
                />
            </div>
        </div>
    );
}

function ShiftTrunkDrop({row, onMove}) {

    const [{ isOver }, dropRef] = useDrop(
        () => ({
            accept: 'shiftTrunk',
            drop: (item) => {
                console.log(`Dropped trunk from (${item.row}) to (${row})`);
                onMove(item.row, row);
            },
            collect: (monitor) => ({
                isOver: !!monitor.isOver(),
                item: monitor.getItem(),
            })
        })
    )

    return (
        <div ref={dropRef} className={isOver?'shift-trunk-drop-over':'shift-trunk-drop'}>
        </div>
    );
}

function ShiftTrunk({trunk, nTrunk, onTrunkChange, onTrunkDelete, onShiftMove}) {

    const [{ isDragging }, drag, preview] = useDrag(
        () => ({
            type: 'shiftTrunk',
            item: { row: nTrunk },
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging()
            })
        }),
        []
    )
    
    function handleShiftAdd(shift) {
        trunk.included.push(shift);
        onTrunkChange(trunk);
    }

    function handleShiftsChange(shifts) {
        trunk.included = shifts;
        onTrunkChange(trunk);
    }

    function handleMinLinesChange(e) {
        trunk.minLines = e.target.value;
        onTrunkChange(trunk);
    }

    return (
        <div 
            className='shift-trunk'
            ref={preview}
            style={{opacity: isDragging?0.4:1}}
        >
            <h4 
                className='shift-trunk-heading'
                >
                <span
                    ref={drag}
                    className='drag-handle'
                >
                    <FontAwesomeIcon size='xs' icon='grip-vertical'/>
                </span>
                &nbsp;
                {trunk.name}
            </h4>
            <div className='shift-trunk-settings-container'>
                <Form>
                    <Form.Label htmlFor={`${trunk.name}-minLines`}>Anzahl Zeilen (mindestens)</Form.Label>
                    <Form.Control
                        id={`${trunk.name}-minLines`}
                        type='number'
                        value={trunk.minLines}
                        min={0}
                        onChange={handleMinLinesChange}
                    />
                </Form>
                <ShiftTrunkShifts 
                    shifts={trunk.included}
                    onShiftsChange={handleShiftsChange}
                    nTrunk={nTrunk}
                    onShiftMove={onShiftMove}
                />
            </div>
            <ShiftTrunkAddShiftModal onShiftAdd={handleShiftAdd}/>
            <Button 
                className='m-4'
                variant='outline-danger'
                onClick={onTrunkDelete}
            >
                <FontAwesomeIcon size='sm' icon='trash'/>
                <span className='m-3'>Schichtcontainer löschen</span>
            </Button>
        </div>
    );
}

function ShiftTrunkSettings({shiftTrunks, onTrunksSet}) {

    function handleTrunkCreate(name) {
        shiftTrunks.push({
            name: name,
            included: [],
            minLines: 0,
        })
        onTrunksSet(shiftTrunks);
    }

    function handleTrunkChange(n, trunk) {
        shiftTrunks[n] = trunk;
        onTrunksSet(shiftTrunks);
    }

    function handleTrunkDelete(n) {
        shiftTrunks.splice(n, 1);
        console.log('Deleting trunk ', JSON.stringify(shiftTrunks, undefined, 2))
        onTrunksSet(shiftTrunks);
    }

    function handleTrunkMove(from, to) {
        if(to > from) {
            to--;
        }
        if(from !== to) {
            const trunk = shiftTrunks[from];
            shiftTrunks.splice(from, 1)
            shiftTrunks.splice(to, 0, trunk);
            onTrunksSet(shiftTrunks);
        }
    }

    function handleShiftMove(from, to) {
        console.log('Moving Shift ', from, to);
        // only move if we're moving
        if(from.row !== to.row || from.column !== to.column){

            // if we're moving forward  in the same trunk ...
            if (from.row === to.row && from.column < to.column ) {
                // adjust final position
                to.column--;
            }

            // store shift
            const shift = shiftTrunks[from.row].included[from.column];
            // remove it from from-trunk
            shiftTrunks[from.row].included.splice(from.column, 1);
            // insert it into to-trunk
            shiftTrunks[to.row].included.splice(to.column, 0, shift);
                
        }
            // update trunks
        onTrunksSet(shiftTrunks);
    }

    return (
        <div className='settings-panel'>
            <h3>Schichtcontainer</h3>
                <div className='shift-trunk-container'>
                    {shiftTrunks.map( (trunk, n) => {
                        return (
                            <div
                                key={`${n}-${trunk.name}-${JSON.stringify(shiftTrunks)}`}
                            >
                                <ShiftTrunkDrop row={n} onMove={handleTrunkMove}/>
                                <ShiftTrunk 
                                    trunk={trunk}
                                    nTrunk={n}
                                    onTrunkChange={(trunk) => {handleTrunkChange(n, trunk)}}
                                    onTrunkDelete={ () => {handleTrunkDelete(n)}}
                                    onShiftMove={handleShiftMove}
                                />
                            </div>

                        )
                    })}
                    <ShiftTrunkDrop row={shiftTrunks.length} onMove={handleTrunkMove}/>
                </div>
            <ShiftTrunkAddContainerModal onTrunkCreate={handleTrunkCreate}/>
        </div>
    );
}

function SaveRestoreSettings({config, onRestoreDefaults}) {
    return (
        <div className='settings-panel'>
            <h3>Wiederherstellung</h3>
            <div className='save-restore-container'>
                <Button as='a' 
                    className='link-to-file' 
                    href={URL.createObjectURL(new Blob([JSON.stringify({config: config.config})]), 'text/json')
                    }
                    download={`Einstellungen-${dayjs().format('YYYY-MM-DD_HH-mm')}.json`}
                >
                    <FontAwesomeIcon icon='download'/>
                    &nbsp; Einstellungen herunterladen
                </Button>                
                <Button 
                    variant='outline-danger' 
                    className='m-3'
                    onClick={onRestoreDefaults}
                >
                    <FontAwesomeIcon size='sm' icon='triangle-exclamation'/>
                    &nbsp;
                    Standardeinstellungen wiederherstellen
                </Button>
            </div>
        </div>
    );
}

function IconSelection({icons, title, onSelect}) {
    const [showGrid, setShowGrid] = useState(false);

    function handleSelect(iconName) {
        if(onSelect !== undefined) {
            onSelect(iconName);
        }
        setShowGrid(false);
    }

    return (
        <div>
            <Button
                onClick={() => setShowGrid(!showGrid)}
            >
                {title}
            </Button>
            {showGrid && 
                <div className='icon-grid-overlay'>
                    {Object.keys(icons).map( iconName => {
                        return (
                            <div
                                key={iconName}
                                className='icon-grid-icon-container'
                                onClick={() => {handleSelect(iconName)}}
                            >
                                <TagIcon icon={icons[iconName]} key={iconName}/>
                            </div>
                        );
                    })}
                </div>
            }
        </div>
    );

}

function IconMappingAddModal({onAdd, icons, title}) {

    const [showModal, setShowModal] = useState(false);
    const [selectedIcon, setSelectedIcon] = useState(null)
    const [selectedName, setSelectedName] = useState('')

    function handleCancel() {
        setShowModal(false);
    }

    function handleSave() {
        onAdd(selectedName, selectedIcon);
        setShowModal(false);
    }

    function handleToggleModal() {
        setSelectedIcon(null);
        setSelectedName('');
        setShowModal(!showModal);
    }

    return (
        <>
        <Button className='m-4' onClick={handleToggleModal}>
            <FontAwesomeIcon size='sm' icon='signs-post'/>
            <span className='m-3'>{title}</span>
        </Button>

        <Modal show={showModal} onHide={handleCancel}>
            <Modal.Header closeButton>
                {title}
            </Modal.Header>
            <Modal.Body>
                <input 
                    autoFocus 
                    name='name'
                    className='m-3'
                    value={selectedName}
                    onChange={ e => {setSelectedName(e.target.value)}}
                />
                {selectedIcon && <TagIcon icon={icons[selectedIcon]}/>}
                <div
                    className='m-3'
                >
                    <IconSelection 
                        icons={icons}
                        title='Icon auswählen'
                        onSelect={(iconName) => {setSelectedIcon(iconName);}}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCancel}>
                    Abbrechen
                </Button>
                <Button 
                    variant="primary"
                    onClick={handleSave}
                    disabled={selectedIcon===null || selectedName===''}
                >
                    Speichern
                </Button>
            </Modal.Footer>
        </Modal>
        </>
    );

}

function IconMapPlate({name, icon, onDelete}) {
    return (
        <div className='icon-map-plate'>
            <span 
                className='inline-badge inline-delete m-2'
                onClick={() => {onDelete(name)}}
            >
                <FontAwesomeIcon size='xs' icon='trash' />
            </span>            

            <span className='m-2'>
                {name}
            </span>
            <TagIcon icon={icon}/>
        </div>
    );
}

function IconMapCategory({title, labelbutton, icons, iconMap, onChange}) {
    
    function handleAdd(name, iconName) {
        iconMap[name] = iconName;
        onChange(iconMap);
    }

    function handleDelete(name) {
        delete iconMap[name];
        onChange(iconMap);
    }

    return (
        <div>
            <h4>{title}</h4>
            <div className='icon-map-container'>
                {Object.keys(iconMap).map( name => {
                    return (
                        <IconMapPlate
                            key={name}
                            name={name}
                            icon={icons[iconMap[name]]}
                            onDelete={handleDelete}
                        />
                    );
                })}
            </div>
            <IconMappingAddModal icons={icons} title={labelbutton} onAdd={handleAdd}/>
        </div>
    );
}

function IconMapSettings({icons, iconMaps, onChange}) {

    function handleChange(iconMap, type) {
        iconMaps[type] = iconMap;
        onChange(iconMaps)
    }

    return (
        <div className='settings-panel'>
            <h3>Iconzuordnung</h3>
            <IconMapCategory
                title='Nach Personalkategorie'
                labelbutton='Neue Zuordnung nach Personalkategorie'
                icons={icons}
                iconMap={iconMaps.categories}
                onChange={(iconMap) => {handleChange(iconMap, 'categories')}}
            />
            <IconMapCategory
                title='Nach Schicht'
                labelbutton='Neue Zuordnung nach Schicht'
                icons={icons}
                iconMap={iconMaps.shifts}
                onChange={(iconMap) => {handleChange(iconMap, 'shifts')}}
            />
            <IconMapCategory
                title='Nach Namen'
                labelbutton='Neue Zuordnung nach Namen'
                icons={icons}
                iconMap={iconMaps.staff}
                onChange={(iconMap) => {handleChange(iconMap, 'staff')}}
            />
        </div>
    );
}


function GeneralSettings({ config, onSetStaffCategoriesFromArray, onIgnoredShiftsAdd, onIgnoredShiftsDelete, onTrunksSet, onRestoreDefaults, onIconMapsChange}) {

    return (
        <DndProvider backend={HTML5Backend} debugMode={true}>
            <h2 className='m-3'>Einstellungen</h2>
            <StaffCategorySettings
                staffCategories={config.staffCategoriesAsArray()}
                onSetStaffCategoriesFromArray={onSetStaffCategoriesFromArray}
            />
            <ShiftTrunkSettings 
                shiftTrunks={config.getTrunks()}
                onTrunksSet={onTrunksSet}
            />
            <IgnoredShiftSettings
                ignoredShifts={config.config.ignoredShifts}
                onIgnoredShiftsAdd={onIgnoredShiftsAdd}
                onIgnoredShiftsDelete={onIgnoredShiftsDelete}
            />
            <IconMapSettings 
                icons={config.config.icons}
                iconMaps={config.config.iconMap}
                onChange={onIconMapsChange}
            />
            <SaveRestoreSettings
                onRestoreDefaults={onRestoreDefaults}
                config={config}
            />
        </DndProvider>
    )
}

export default GeneralSettings;