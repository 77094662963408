import dayjs from 'dayjs';

import { dateRange } from './tools';

function unique(array1, array2) {
    return [...new Set([...array1, ...array2])];
}

function clone(obj) {
    return JSON.parse(JSON.stringify(obj))
}

class Roster {
    constructor(staff = {}, categories = {}) {
        this.staff = staff;
        this.categories = categories;
    }

    merge(other) {
        // clone both staff lists
        const thisStaff = clone(this.staff);
        const otherStaff = clone(other.staff);
        
        // do actual merging
        let staff = {}
        const staffNames = unique(Object.keys(thisStaff), Object.keys(otherStaff))
        staffNames.forEach( staffName => {
            staff[staffName] = Object.assign({}, thisStaff[staffName], otherStaff[staffName])
        });

        // clone both category lists
        const thisCategories = clone(this.categories)
        const otherCategories = clone(other.categories)
        
        const categories = Object.assign({}, thisCategories, otherCategories)

        return new Roster(staff, categories);
    }

    addShift(employee, date, shift) {
        const dateString = date.format('YYYY-MM-DD');
        return this.merge({staff:{[employee]:{[dateString]:shift}}, categories: {}});
    }

    deleteShift(employee, date) {
        const dateString = date.format('YYYY-MM-DD');
        // delete shift first
        delete this.staff[employee][dateString];
        // check if employees roster is now empty ...
        if(Object.keys(this.staff[employee]).length === 0) {
            console.log(`${employee} shift list now empty. Deleting.`)
            // ... then also delete the employee
            delete this.staff[employee]
        }
        return new Roster(clone(this.staff), clone(this.categories));
    }

    dates() {
        // store found dates
        let dates = [];
        
        // get all dates from all employees
        Object.keys(this.staff).forEach( staff => {
            dates = dates.concat(Object.keys(this.staff[staff]));
        });

        // filter out all duplicates
        dates = [...new Set(dates)];
        
        dates = dates.sort();

        // convert to datejs objects and return
        return dates.map( date => {
            return dayjs(date, 'YYYY-MM-DD');
        })
    }

    names() {
        return Object.keys(this.staff);
    }

    shifts(name) {
        return Object.keys(this.staff[name]).map( date => {
            return this.staff[name][date];
        })
    }

    allCategories() {
        return [...new Set(Object.values(this.categories))]
    }

    allOccuringShifts() {
        let shifts = [];
        this.names().forEach( name => {
            shifts = [...shifts, ...this.shifts(name)]
        })
        return [...new Set(shifts)];
    }

    namesTopBottom(config) {
        let sorted = this.namesSorted(config);
        let top = [];
        let bottom = [];

        sorted.forEach( name => {
            const category = this.categories[name];
            if(category && config.config.staffCategories[category] && config.config.staffCategories[category].position === 'top') {
                top.push(name);
            } else {
                bottom.push(name);
            }
        })

        return {top, bottom};
    }

    namesSorted(config) {
        return this.names().sort( (a, b) => {
            const catA = this.categories[a];
            const catB = this.categories[b];
            const staffCategories = config.config.staffCategories;

            if(catA === undefined) {
                return 1;
            }

            if(catB === undefined) {
                return -1;
            }

            if(staffCategories[catA].position === 'top' && staffCategories[catB].position === 'bottom') {
                return -1;
            }
            
            if(staffCategories[catA].position === 'bottom' && staffCategories[catB].position === 'top') {
                return 1;
            }

            if(staffCategories[catA].priority < staffCategories[catB].priority) {
                return -1;
            }

            if(staffCategories[catA].priority > staffCategories[catB].priority) {
                return 1;
            }

            const firstShiftA = this.shifts(a)[0];
            const firstShiftB = this.shifts(b)[0];

            let comparison = 0;
            config.config.shiftTrunks.forEach( trunk => {
                   if(trunk.included.indexOf(firstShiftA) > trunk.included.indexOf(firstShiftB)) {
                    comparison = 1
                }
                if(trunk.included.indexOf(firstShiftA) < trunk.included.indexOf(firstShiftB)) {
                    comparison = -1;
                }
            })
            if (comparison !== 0) {
                return comparison;
            }

            const staffNames = this.names();
            const indexA = staffNames.indexOf(a);
            const indexB = staffNames.indexOf(b);

            if(indexA > indexB) {
                return 1;
            }
            if(indexB > indexA) {
                return -1;
            }
            if(indexB === indexA) {
                return 0;
            }

            return a.localeCompare(b)
        });
    }

    nStaff() {
        return Object.keys(this.staff).length;
    }

    subrosterShifts(includedShifts) {
        let staff = {}
        Object.keys(this.staff).forEach( staffName => {
            let staffDates = {}
            Object.keys(this.staff[staffName]).forEach( date => {
                if (includedShifts.indexOf(this.staff[staffName][date]) >= 0) {
                    staffDates[date] = this.staff[staffName][date];
                }
            });
            if (Object.keys(staffDates).length !== 0) {
                staff[staffName] = staffDates;
            }
        });

        return new Roster(staff, clone(this.categories));
    }

    subroster(dates) {
        // convert dates to strings
        const datesString = dates.map( date => {
            return date.format("YYYY-MM-DD");
        })

        let staff = {}
        Object.keys(this.staff).forEach( staffName => {
            let staffDates = {}
            Object.keys(this.staff[staffName]).forEach( date => {
                if (datesString.indexOf(date) >= 0) {
                    staffDates[date] = this.staff[staffName][date];
                }
            });
            if (Object.keys(staffDates).length !== 0) {
                staff[staffName] = staffDates;
            }
        });

        return new Roster(staff, clone(this.categories));
    }

    subrosterWeek(week) {
        const start = dayjs().year(week.year).isoWeek(week.week).startOf('week');
        const end = dayjs().year(week.year).isoWeek(week.week).endOf('week');

        const dates = dateRange(start, end);

        return this.subroster(dates);
    }

    subrosterStaffCategories(staffCategories) {
        let staff = {}
        Object.keys(this.staff).forEach( name => {
            const category = this.categories[name];
            if(staffCategories[category] && !staffCategories[category].ignore ) {
                staff[name] = this.staff[name];
            }
        })

        return new Roster(staff, clone(this.categories));
    }

    staffIcons(name, config) {
        let icons = [];
        
        if (config.config.iconMap.staff[name]) {
            icons.push(config.config.icons[config.config.iconMap.staff[name]]);
        }

        if (config.config.iconMap.categories[this.categories[name]]) {
            icons.push(config.config.icons[config.config.iconMap.categories[this.categories[name]]])
        }

        const shifts = this.shifts(name);
        Object.keys(config.config.iconMap.shifts).forEach( shift => {
            if( shifts.indexOf(shift) >= 0 ) {
                icons.push(config.config.icons[config.config.iconMap.shifts[shift]]);
            }
        });

        return clone(icons);
    }

    isoWeeks() {
        // store day count for all found isoWeeks
        let weeks = {}

        // loop over all dates
        this.dates().forEach( date => {
            // calculate week number and week year
            const week = date.isoWeek();
            const year = date.isoWeekYear();
            
            // if we have an entry for the year
            if (year in weeks) {
                
                // ... if we have an entry for the week
                if (week in weeks[year]) {
                    // increment day count
                    weeks[year][week]++;
                } else {
                    // create day count for week, set it to 1
                    weeks[year][week] = 1;
                }
            } else {
                // create entry for year
                weeks[year] = {};
                // set new daycount for this week to 1
                weeks[year][week] = 1;
            }

        })

        // generate array to return
        let ret = [];
        for (const year in weeks){
            for (const week in weeks[year]) {
                ret.push({
                    year: parseInt(year), 
                    week: parseInt(week),
                    complete: weeks[year][week] === 7,
                });
            }
        }
        return ret;        
    }
}

export default Roster;