import { jsPDF } from "jspdf";
import './Roboto-Condensed-normal';
import './Roboto-Condensed-bold';
import './Font-Awesome-normal';
import { getHolidays } from "./tools";

const MARGIN_LEFT = 5;
const MARGIN_TOP = 5;
// const PAGE_HEIGHT = 297;
const PAGE_WIDTH = 210;

const COL_WIDTH = 29;
const COL_MARGIN = 0.5;
const ROW_HEIGHT = 5;

const TABLE_HEAD_HEIGHT = 10.5;
const TABLE_HEAD_HEIGHT_HOLIDAY = 12.5;
const TABLE_HEAD_BORDER_RADIUS = 1;

const DAY_LABEL_HEIGHT = 2.2;

const TRUNK_HEADING_HEIGHT = 8;
const TRUNK_MARGIN_TOP = 2;
const TRUNK_BORDER_RADIUS = 1;

const NAME_MARGIN_LEFT = 1;
const NAME_FONT_SIZE = 10;
const NAME_ICON_RADIUS = 2;
const NAME_ICON_MARGIN = 0.7;
const NAME_MARGIN_TOP = 0.8;

class RenderPDF{
    constructor(roster, config, nameMapping){
        this.roster = roster;
        this.config = config;
        this.nameMapping = nameMapping;
        this.setTableHeadHeight();

        this.doc = new jsPDF({
            orientation: 'p',
            unit: 'mm',
            format: 'a4',
            putOnlyUsedFonts:true});
        this.renderDates();
        this.renderShiftTrunks();
    }
    
    setTableHeadHeight() {
        this.TABLE_HEAD_HEIGHT = this.isAnyHoliday(this.roster.dates())? TABLE_HEAD_HEIGHT_HOLIDAY : TABLE_HEAD_HEIGHT;
    }

    isAnyHoliday(dates) {
        return dates.some(this.isHoliday);
    }

    isHoliday(date) {
        const holidays = getHolidays(date.year());
        for (const holiday of holidays) {
            if(holiday.date.isSame(date, 'day')) {
                return holiday;
            }
        }
        return null;
    }

    setDayBasedColor(date, fillStroke='FD'){

        const holiday = this.isHoliday(date);

        let dayType = 0; // 0 = workday, 1 = half holiday, 2 = full holiday
        let dayName = '';

        if( holiday !== null ) {
            dayType = holiday.full? 2 : ((date.day()===0)? 2 : 1);
            dayName = holiday.name;
        } else {
            // sunday
            if (date.day() === 0) {
                dayType = 2;
            }
            // saturday
            if (date.day() === 6) {
                dayType = 1;
            }
        }

        if (dayType === 2){
            if(fillStroke === 'FD') {
                this.doc.setFillColor('#fff8f8');
            } else {
                this.doc.setFillColor('#fff');
            }
            this.doc.setTextColor('#700')
            this.doc.setDrawColor('#c00');
            return dayName;
        }
        if (dayType === 1){
            if(fillStroke === 'FD') {
                this.doc.setFillColor('#fffcfc');
            } else {
                this.doc.setFillColor('#fff');
            }
            this.doc.setTextColor('#700')
            this.doc.setDrawColor('#c00');
            return dayName;
        }

        this.doc.setFillColor('#fff');
        this.doc.setTextColor('#000')
        this.doc.setDrawColor('#000');

        return dayName;
    }

    resetColors(){
        this.doc.setFillColor('#fff');
        this.doc.setDrawColor('#000');
        this.doc.setTextColor('#000')

    }

    renderDates(){
        const dates = this.roster.dates();

        
        dates.forEach( (date, n) => {
            
            
            // draw frame
            const dayName = this.setDayBasedColor(date);
            this.doc.roundedRect(
                MARGIN_LEFT + n*COL_WIDTH + COL_MARGIN, 
                MARGIN_TOP, 
                COL_WIDTH - 2*COL_MARGIN, 
                this.TABLE_HEAD_HEIGHT,
                TABLE_HEAD_BORDER_RADIUS,
                TABLE_HEAD_BORDER_RADIUS,
                'FD'
                );
                
            // put date
            this.doc.setFont("Roboto-Condensed", "bold");
            this.doc.setFontSize(13);
            this.doc.text(
                [date.format("dddd"), date.format("DD.MM.")],
                MARGIN_LEFT + n*COL_WIDTH + COL_WIDTH/2, 
                MARGIN_TOP, 
                {align: 'center', baseline: 'top'}
            )

            this.doc.setFont("Roboto-Condensed", "normal");
            this.doc.setFontSize(5);
            this.doc.text(
                dayName,
                MARGIN_LEFT + n*COL_WIDTH + COL_WIDTH/2, 
                MARGIN_TOP + this.TABLE_HEAD_HEIGHT - DAY_LABEL_HEIGHT, 
                {align: 'center', baseline: 'top'}
            )

            this.resetColors()

        });
    }

    renderTextIcon(icon, x, y, radius){
        this.doc.setFillColor(icon.background);
        this.doc.circle(x, y, radius, 'F');
        this.doc.setFont("Roboto-Condensed", "bold");
        this.doc.setFontSize(radius*3.9);
        this.doc.setTextColor(icon.color);
        this.doc.text(icon.text, x, y, {align: 'center', baseline: 'middle'})
        
    }

    renderPictureIcon(icon, x, y, radius){
        this.doc.setFillColor(icon.background);
        this.doc.circle(x, y, radius, 'F');
        this.doc.setFont("Font-Awesome", "normal");
        this.doc.setFontSize(radius*3.3);
        this.doc.setTextColor(icon.color);
        this.doc.text(icon.unicode, x, y, {align: 'center', baseline: 'middle'})
    }

    renderIcon(icon, x, y, radius) {
        if (icon.type === 'picture') {
            this.renderPictureIcon(icon, x, y, radius);
        } else {
            this.renderTextIcon(icon, x, y, radius);
        }
    }

    renderName(name, x, y, icons){
        const mappedName = this.nameMapping.map(name);
        
        this.doc.setFont("Roboto-Condensed", "normal");
        this.doc.setFontSize(NAME_FONT_SIZE);
        this.doc.setTextColor("#000");

        this.doc.text(mappedName, x, y, {align: 'left', baseline: 'top'});
        
        // const iconStartX = this.doc.getTextWidth(mappedName)
        const iconStartX = COL_WIDTH - 4*COL_MARGIN;
        icons.forEach( (icon, n) => {
            this.renderIcon(
                icon,
                x + iconStartX - NAME_ICON_MARGIN - NAME_ICON_RADIUS - n*(NAME_ICON_MARGIN + 2*NAME_ICON_RADIUS),
                y + 0.5*ROW_HEIGHT - NAME_ICON_RADIUS*0.4,
                NAME_ICON_RADIUS
            );
        })
    }
    
    renderNames(names, x, y, subroster){
        names.forEach( (name, n) => {
            this.renderName(name, x, y + n*ROW_HEIGHT + NAME_MARGIN_TOP, subroster.staffIcons(name, this.config));
        })
    }

    markBottom(x, y, w, h) {
        this.doc.setFillColor('#ffd899');
        this.doc.roundedRect(
            x,
            y,
            w,
            h,
            TRUNK_BORDER_RADIUS,
            TRUNK_BORDER_RADIUS,
            'FD'
        )
    }

    renderShiftTrunk(trunk, startHeight, nRows){
        
        // heading
        this.doc.setFont("Roboto-Condensed", "bold");
        this.doc.setFontSize(16);
        this.doc.setTextColor("#000");

        this.doc.text(
            trunk.name,
            PAGE_WIDTH/2,
            startHeight,
            {align: 'center', baseline: 'top'}
        )
        
        this.roster.dates().forEach( (date, n) => {
            // draw rect around trunk
            this.setDayBasedColor(date, 'FD');
            this.doc.roundedRect(
                MARGIN_LEFT + n*COL_WIDTH + COL_MARGIN,
                startHeight + TRUNK_HEADING_HEIGHT + TRUNK_MARGIN_TOP,
                COL_WIDTH - 2*COL_MARGIN,
                nRows * ROW_HEIGHT,
                TRUNK_BORDER_RADIUS,
                TRUNK_BORDER_RADIUS,
                'FD'
            )
            this.resetColors();

            // calculate subroster for this date and shift
            const subroster = this.roster.subroster([date]).subrosterShifts(trunk.included);

            // get top and bottom names for said roster
            const {top, bottom} = subroster.namesTopBottom(this.config);

            // mark bottom names
            if (bottom.length > 0) {
                this.setDayBasedColor(date, 'S');
                this.markBottom(
                    MARGIN_LEFT + n*COL_WIDTH + COL_MARGIN,
                    startHeight + TRUNK_HEADING_HEIGHT + TRUNK_MARGIN_TOP + (nRows - bottom.length)*ROW_HEIGHT,
                    COL_WIDTH - 2*COL_MARGIN,
                    bottom.length * ROW_HEIGHT
                );
            }

            // render top names
            this.renderNames(
                top,
                MARGIN_LEFT + n*COL_WIDTH + COL_MARGIN + NAME_MARGIN_LEFT,
                startHeight + TRUNK_HEADING_HEIGHT + TRUNK_MARGIN_TOP,
                subroster
            )

            // render bottom names
            this.renderNames(
                bottom,
                MARGIN_LEFT + n*COL_WIDTH + COL_MARGIN + NAME_MARGIN_LEFT,
                startHeight + TRUNK_HEADING_HEIGHT + TRUNK_MARGIN_TOP + ROW_HEIGHT*(nRows - bottom.length),
                subroster,
            )

        })


        return TRUNK_HEADING_HEIGHT + 2*TRUNK_MARGIN_TOP + nRows*ROW_HEIGHT;
    }

    renderShiftTrunks(){
        let currentHeight = MARGIN_TOP + this.TABLE_HEAD_HEIGHT + TRUNK_MARGIN_TOP;

        const maxTrunkSizes = this.calculateMaxTrunkSizes();

        this.config.config.shiftTrunks.forEach( (trunk, n) => {
            if (maxTrunkSizes[n] > 0) {
                currentHeight += this.renderShiftTrunk(trunk, currentHeight, maxTrunkSizes[n])
            }
        })
    }

    calculateMaxTrunkSizes() {
        let maxSizes = [];
        this.config.config.shiftTrunks.forEach( trunk => {
            let maxSize = trunk.minLines;
            this.roster.dates().forEach( date => {
                const subroster = this.roster.subroster([date]).subrosterShifts(trunk.included);
                maxSize = Math.max(subroster.nStaff(), maxSize);
            });
            maxSizes.push(maxSize)
        });
        return maxSizes;
    }

    toDataURIString(){
        return this.doc.output('datauristring')
    }

    toBlobURL(){
        const pdf = new Blob([this.doc.output("blob")], {type: "application/pdf"})
        return URL.createObjectURL(pdf);
    }

}

export default RenderPDF;