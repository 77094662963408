
class Config{
    constructor(config={}){

        let local = {}
        
        // try to read name mapping from local storage
        try {
            local = JSON.parse(localStorage.getItem('config'));
            if (local === null) {
                local = {};
            }
        } catch {
            local = {};
        }

        if(Object.keys(config).length === 0) {
            if(Object.keys(local).length === 0) {
                this.config = this.loadDefaults();
            } else {
                this.config = local;
            }
        } else {
            this.config = JSON.parse(JSON.stringify(config));
            this.saveLocal();
        }
    }

    restoreDefaults() {
        return new Config(this.loadDefaults());
    }

    saveLocal() {
        try {
            localStorage.setItem('config', JSON.stringify(this.config));
        } catch {
            console.warn("Couldn't save config to local storage");
        }
    }

    shiftTrunks(){
        return JSON.parse(JSON.stringify(this.config.shiftTrunks));
    }

    allIncludedShifts() {
        let shifts = [];
        this.config.shiftTrunks.forEach( trunk => {
            shifts = [...shifts, ...trunk.included]
        })
        return [...new Set(shifts)];
    }

    allShifts() {
        return [...new Set([...this.allIncludedShifts(), ...this.config.ignoredShifts])]
    }

    addStaffCategory(categoryName, categorySettings){
        this.config.staffCategories[categoryName] = categorySettings;
        return new Config(this.config)
    }

    deleteStaffCategory(categoryName) {
        delete this.config.staffCategories[categoryName];
        return new Config(this.config);
    }

    staffCategoriesAsArray() {
        let top = [];
        let bottom = [];
        let ignore = [];

        function comparePriority(a, b) {
            if(a.priority < b.priority) return -1;
            if(a.priority > b.priority) return 1;
            return 0;
        }

        Object.keys(this.config.staffCategories).forEach( categoryName => {
            if(this.config.staffCategories[categoryName].ignore) {
                ignore.push(categoryName);
            } else {
                if(this.config.staffCategories[categoryName].position === 'top'){
                    top.push(categoryName);
                } else {
                    bottom.push(categoryName);
                }
            }
        });

        const ret = [
                top.sort(comparePriority), 
                bottom.sort(comparePriority),
                ignore.sort(comparePriority)
        ];

        return ret;

    }

    setStaffCategoriesFromArray([top, bottom, ignore]){
        let staffCategories = {}

        top.forEach( (categoryName, n) => {
            staffCategories[categoryName] = {
                ignore: false,
                position: 'top',
                priority: n,
            }
        });
        bottom.forEach( (categoryName, n) => {
            staffCategories[categoryName] = {
                ignore: false,
                position: 'bottom',
                priority: n,
            }
        });
        ignore.forEach( (categoryName, n) => {
            staffCategories[categoryName] = {
                ignore: true,
                position: 'top',
                priority: n,
            }
        });
        
        this.config.staffCategories = staffCategories;
        
        return new Config(this.config);
    }

    addIgnoredShift(shift) {
        this.config.ignoredShifts.push(shift);
        return new Config(this.config);
    }

    deleteIgnoredShift(shift) {
        const index = this.config.ignoredShifts.indexOf(shift);
        if(index >= 0) {
            this.config.ignoredShifts.splice(index, 1);
        }
        return new Config(this.config);
    }

    setTrunks(trunks) {
        this.config.shiftTrunks = JSON.parse(JSON.stringify(trunks));
        return new Config(this.config);
    }

    getTrunks() {
        return JSON.parse(JSON.stringify(this.config.shiftTrunks));
    }

    changeIconMaps(iconMaps) {
        this.config.iconMap = iconMaps;
        return new Config(this.config);
    }

    loadDefaults(){
        return {
            ignoredShifts : [
                '657', '647', '613', '610', '740', '609', '625', '1424', '600', '960', '601', '620', '635', '645', '889', '607', '636', '618', '604', '632', '622', '623', '628'
            ],
            shiftTrunks : [
                {
                    name: 'Frühdienst', 
                    included: ['4051', '1000', '1002', '1500', '1506', '1503', '1518', '4100', '1001', '1004'],
                    minLines: 15,
                },
                {
                    name: 'Zwischendienst',
                    included: ['1064', '1079'],
                    minLines: 1,
                },
                {
                    name: 'Spätdienst',
                    included: ['1092', '1501', '1504', '1519', '4300'],
                    minLines: 13,
                },
                {
                    name: 'später Spätdienst (S1)',
                    included: ['1093'],
                    minLines: 1,
                },
                {
                    name: 'Nachtdienst',
                    included: ['1106', '4400', '1502', '1505', '1508', '1520'],
                    minLines: 10
                }
            ],
            staffCategories : {
                'Pflegekräfte - 1': {
                    ignore: false,
                    position: 'top',
                    priority: 0,
                },
                'Pflegedienst Fachkraft in Anerkennung - 1': {
                    ignore: false,
                    position: 'top',
                    priority: 1,
                },
                'Auszubildenden': {
                    ignore: false,
                    position: 'bottom',
                    priority: 0,
                },
                'Auszubildenden OTA': {
                    ignore: false,
                    position: 'bottom',
                    priority: 1,
                },
                'Praktikanten Intern': {
                    ignore: false,
                    position: 'bottom',
                    priority: 2,
                },
                'Pflegedienst Med.Studenten 1': {
                    ignore: false,
                    position: 'bottom',
                    priority: 3,
                },
                'Externe MA': {
                    ignore: false,
                    position: 'bottom',
                    priority: 4,
                },
                'Angestellten - 1': {
                    ignore: false,
                    position: 'bottom',
                    priority: 5,
                },
                'Servicepersonal 1': {
                    ignore: false,
                    position: 'bottom',
                    priority: 6,
                },                
            },
            icons : {
                'service' : {
                    type: 'picture',
                    icon: 'gear',
                    unicode: '\uf013',
                    color: '#fff',
                    background: '#008'
                },
                'azubi' : {
                    type: 'picture',
                    icon: 'graduation-cap',
                    unicode: '\uf19d',
                    color: '#000',
                    background: '#f7f'
                },
                'graduate' : {
                    type: 'picture',
                    icon: 'user-graduate',
                    unicode: '\uf501',
                    color: '#f7f',
                    background: '#000'
                },
                'external' : {
                    type: 'picture',
                    icon: 'truck-medical',
                    unicode: '\uf0f9',
                    color: '#fff',
                    background: '#f00'
                },
                'crown' : {
                    type: 'picture',
                    icon: 'crown',
                    unicode: '\uf521',
                    color: '#ff0',
                    background: '#440'
                },
                'training' : {
                    type: 'picture',
                    icon: 'handshake-angle',
                    unicode: '\uf4c4',
                    color: '#008',
                    background: '#aaf'
                },
                'flask' : {
                    type: 'picture',
                    icon: 'flask',
                    unicode: '\uf0c3',
                    color: '#040',
                    background: '#afa'
                },
                'heartcheck' : {
                    type: 'picture',
                    icon: 'heart-circle-check',
                    unicode: '\ue4fd',
                    color: '#e88',
                    background: '#ddd'
                },
                'nurse' : {
                    type: 'picture',
                    icon: 'user-nurse',
                    unicode: '\uf82f',
                    color: '#ff0',
                    background: '#f66'
                },
                'translator' : {
                    type: 'picture',
                    icon: 'comments',
                    unicode: '\uf086',
                    color: '#fff',
                    background: '#333'
                },
                'internal' : {
                    type: 'picture',
                    icon: 'hospital',
                    unicode: '\uf0f8',
                    color: '#fff',
                    background: '#f99'
                },
                'ota' : {
                    type: 'picture',
                    icon: 'syringe',
                    unicode: '\uf48e',
                    color: '#040',
                    background: '#afa'
                },
                'heart' : {
                    type: 'picture',
                    icon: 'heart',
                    unicode: '\uf004',
                    color: '#f00',
                    background: '#444'
                },
                'heartpulse' : {
                    type: 'picture',
                    icon: 'heart-pulse',
                    unicode: '\uf21e',
                    color: '#ff0',
                    background: '#33f'
                },
                'meteor' : {
                    type: 'picture',
                    icon: 'meteor',
                    unicode: '\uf753',
                    color: '#ff0',
                    background: '#000'
                },
                'sun' : {
                    type: 'picture',
                    icon: 'sun',
                    unicode: '\uf185',
                    color: '#ff0',
                    background: '#aaf'
                },
                'f0' : {
                    type: 'text',
                    text: 'F',
                    color: '#fff',
                    background: '#070'
                },
                'f1' : {
                    type: 'text',
                    text: 'F1',
                    color: '#fff',
                    background: '#070'
                },
                'f2' : {
                    type: 'text',
                    text: 'F2',
                    color: '#fff',
                    background: '#070'
                },
                'f3' : {
                    type: 'text',
                    text: 'F3',
                    color: '#fff',
                    background: '#070'
                },
                'f4' : {
                    type: 'text',
                    text: 'F4',
                    color: '#fff',
                    background: '#070'
                },
                'f5' : {
                    type: 'text',
                    text: 'F5',
                    color: '#fff',
                    background: '#070'
                },
                'z0' : {
                    type: 'text',
                    text: 'Z',
                    color: '#fff',
                    background: '#070'
                },
                'z1' : {
                    type: 'text',
                    text: 'Z1',
                    color: '#fff',
                    background: '#070'
                },
                'z2' : {
                    type: 'text',
                    text: 'Z2',
                    color: '#fff',
                    background: '#070'
                },
                'z3' : {
                    type: 'text',
                    text: 'Z3',
                    color: '#fff',
                    background: '#070'
                },
                'z4' : {
                    type: 'text',
                    text: 'Z4',
                    color: '#fff',
                    background: '#070'
                },
                'z5' : {
                    type: 'text',
                    text: 'Z5',
                    color: '#fff',
                    background: '#070'
                },
                'S0' : {
                    type: 'text',
                    text: 'S',
                    color: '#fff',
                    background: '#007'
                },
                'S1' : {
                    type: 'text',
                    text: 'S1',
                    color: '#fff',
                    background: '#007'
                },
                'S2' : {
                    type: 'text',
                    text: 'S2',
                    color: '#fff',
                    background: '#007'
                },
                'S3' : {
                    type: 'text',
                    text: 'S3',
                    color: '#fff',
                    background: '#007'
                },
                'S4' : {
                    type: 'text',
                    text: 'S4',
                    color: '#fff',
                    background: '#007'
                },
                'S5' : {
                    type: 'text',
                    text: 'S5',
                    color: '#fff',
                    background: '#007'
                },
                'N0' : {
                    type: 'text',
                    text: 'N',
                    color: '#fff',
                    background: '#000'
                },
                'N1' : {
                    type: 'text',
                    text: 'N1',
                    color: '#fff',
                    background: '#000'
                },
                'N2' : {
                    type: 'text',
                    text: 'N2',
                    color: '#fff',
                    background: '#000'
                },
                'N3' : {
                    type: 'text',
                    text: 'N3',
                    color: '#fff',
                    background: '#000'
                },
                'N4' : {
                    type: 'text',
                    text: 'N4',
                    color: '#fff',
                    background: '#000'
                },
                'N5' : {
                    type: 'text',
                    text: 'N5',
                    color: '#fff',
                    background: '#000'
                },

            },
            iconMap : {
                categories: {
                    'Servicepersonal 1': 'service',
                    'Auszubildenden': 'azubi',
                    'Externe MA': 'external',
                    'Pflegedienst Fachkraft in Anerkennung - 1': 'graduate',
                    'Praktikanten Intern' : 'internal',
                    'Pflegedienst Med.Studenten 1': 'flask',
                    'Auszubildenden OTA': 'ota',
                    'Angestellten - 1': 'nurse',
                },
                shifts: {
                    '1001' : 'f1',
                    '1004' : 'f4',
                    '1079' : 'z1',
                    '4051' : 'crown',
                    '4100' : 'training',
                    '4300' : 'training',
                    '4400' : 'training',
                    '1518' : 'heartcheck',
                    '1519' : 'heartcheck',
                    '1520' : 'heartcheck',
                    '1500' : 'heartcheck',
                    '1501' : 'heartcheck',
                    '1502' : 'heartcheck',
                    '1503' : 'heartcheck',
                    '1504' : 'heartcheck',
                    '1505' : 'heartcheck',
                    '1506' : 'heartcheck',
                    '1508' : 'heartcheck',
                },
                staff: {
                }
            }
        }
    }

}

export default Config;