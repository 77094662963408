import {useState, useRef} from 'react';

import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { BrowserRouter, Routes, Route, NavLink } from "react-router-dom";

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus, faTrash, faGear, faUserGraduate, faTruckMedical, faGraduationCap, faCrown, faHandshakeAngle, faFlask, faHeartCircleCheck, faUserNurse, faComments, faPen, faDownload, faUpload, faTrashArrowUp, faPaste, faHospital, faSyringe, faCirclePlus, faCalendarPlus, faSquarePlus, faGripVertical, faTriangleExclamation, faSignsPost, faFilePdf, faHeart, faHeartPulse, faMeteor, faSun } from '@fortawesome/free-solid-svg-icons'


import dayjs from 'dayjs';
import 'dayjs/locale/de';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isoWeek from 'dayjs/plugin/isoWeek'

// import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';

import {parseClipboard} from './tools';
import Roster from './Roster';
import NameMapping from './NameMapping';
import Config from './Config';
import RenderPDF from './RenderPDF';
import NameSettings from './NameSettings';
import GeneralSettings from './GeneralSettings';
import { TagIcon } from './defaultElements';

library.add(faUserPlus, faTrash, faGear, faUserGraduate, faTruckMedical, faGraduationCap, faCrown, faHandshakeAngle, faFlask, faHeartCircleCheck, faUserNurse, faComments, faPen, faDownload, faUpload, faTrashArrowUp, faPaste, faHospital, faSyringe, faCirclePlus, faCalendarPlus, faSquarePlus, faGripVertical, faTriangleExclamation, faSignsPost, faFilePdf, faHeart, faHeartPulse, faMeteor, faSun);

dayjs.extend(customParseFormat);
dayjs.extend(isoWeek);
dayjs.locale('de');


function IsoWeekSelection({weeks, onSelect, selectedWeek}) {

  function isSelected(week) {
    return (selectedWeek && selectedWeek.week === week.week && selectedWeek.year === week.year);
  }

  return (
    <ButtonGroup className='week-buttons'>

      {weeks.map( week => {
        return (
          <Button 
            variant={(isSelected(week)?'':'outline-')+(week.complete?'primary':'danger')}
            onClick={() => {onSelect(week)}}
            key={`${week.year}-${week.week}`}
          >
            {week.year} KW{week.week}
          </Button>
        );
      })}
    </ButtonGroup>
  );

}

function PDFOutput({roster, config, nameMapping}) {
  const blobURL = new RenderPDF(roster, config, nameMapping).toBlobURL();

  const week = roster.isoWeeks()[0] || {year:'XX', week:'XX'};
  
  return (
    <div className='pdf-ouput-container'>
      <div>
        <Button 
          as='a'
          variant='outline-primary'
          className='link-to-file m-2'
          href={blobURL}
          download={`Schmierplan-KW${week.week}-${week.year}.pdf`}
        >
          <FontAwesomeIcon icon='file-pdf' />
          &nbsp;
          Download
        </Button>
      </div>
      <iframe className="print-frame" src={blobURL} title="Drucken"></iframe>
    </div>
  );
}

function WeeklyRoster({week, roster, config, nameMapping, onNameMappingChange, names, onAddShift, onDeleteShift}) {
  
  const [showPDF, setShowPDF] = useState(false);

  function handleShowPlan(){
    setShowPDF(false)
  }

  function handleShowPDF(){
    setShowPDF(true)
  }

  const dates = roster.dates();
  const startOfWeek = dayjs().year(week.year).isoWeek(week.week).startOf('week');
  const endOfWeek = startOfWeek.endOf('week');

  return (
    <div>
      <div>
        <h3 className='p-0 m-2'>
          Kalenderwoche {week.week}
        </h3>
        <h5 className='p-0 m-2 text-muted'>
          {startOfWeek.format("DD.MM.")} bis {endOfWeek.format("DD.MM.")}
        </h5>
        <p>
          <Badge pill bg={week.complete?'primary':'danger'}>{week.complete?'vollständig':'unvollständig'}</Badge>
        </p>

        <ButtonGroup className='show-pdf-buttons'>
          <Button 
            onClick={handleShowPlan}
            variant={showPDF?'outline-primary':'primary'}
          >
            Plan
          </Button>
          <Button 
            onClick={handleShowPDF}
            variant={showPDF?'primary':'outline-primary'}
          >
            PDF
          </Button>

        </ButtonGroup>
      </div>
      {showPDF? 
        <PDFOutput roster={roster} config={config} nameMapping={nameMapping}/>
      :
        <div 
          className='week-container'
        >
          {dates.map((date, col) => {
              return (
                <DailyRoster 
                  date={date}
                  column={col+1}
                  roster={roster.subroster([date])}
                  config={config}
                  nameMapping={nameMapping}
                  onNameMappingChange={onNameMappingChange}
                  names={names}
                  onAddShift={onAddShift}
                  onDeleteShift={onDeleteShift}
                  key={date.format()}
                />
              )
          })}
        </div>        
      }
    </div>
);
}

function DailyRoster({date, roster, config, nameMapping, onNameMappingChange, names, onAddShift, onDeleteShift, column}) {
  return (
      <>
        <h4>{date.format("ddd DD.MM.")}</h4>
        {config.shiftTrunks().map( (shiftDefinition, row) => {
          return (
                <ShiftRoster 
                  column={column}
                  row={row+2}
                  roster={roster.subrosterShifts(shiftDefinition.included)}
                  config={config}
                  nameMapping={nameMapping}
                  onNameMappingChange={onNameMappingChange}
                  names={names}
                  shiftDefinition={shiftDefinition}
                  date={date}
                  onAddShift={onAddShift}
                  onDeleteShift={onDeleteShift}
                  key={shiftDefinition.name}
                />
          )})}
        </>
  );
}

function ShiftRoster({roster, config, nameMapping, onNameMappingChange, names, shiftDefinition, date, onAddShift, onDeleteShift, column, row}) {
  
  let [showModal, setShowModal] = useState(false);
  let shiftRef = useRef(null);
  let nameRef = useRef(null);

  function handleCancel(){
    setShowModal(false);
  }

  function handleSave(){
    onAddShift(nameRef.current.value, date, shiftRef.current.value);
    setShowModal(false);
  }

  function handleDeleteShift(name) {
    onDeleteShift(name, date);
  }

  function handleDeleteAll() {
    roster.names().forEach( name => {
      onDeleteShift(name, date)
    });
  }

  return (
    <>
      <Modal show={showModal} onHide={handleCancel}>
        <Modal.Header>
          Person zu "{shiftDefinition.name}" hinzufügen
        </Modal.Header>
        <Modal.Body>
          <label htmlFor="shift">Dienst </label>
          <select name="shift" ref={shiftRef}>
            {shiftDefinition.included.map( shift => {
              return (
                <option value={shift} key={shift}>{shift}</option>
              );
            })}
          </select>
          <label htmlFor='name'>Name</label>
          <select autoFocus name='name' list="names" ref={nameRef}>
            {names.map( name => {
              return (<option value={name} key={name}>{name}</option>)
            })}
          </select>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel}>
            Abbrechen
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Speichern
          </Button>
        </Modal.Footer>
      </Modal>
      <Card 
        key={shiftDefinition.name}
        className='shift-container'
        style={{gridColumnStart: column, gridRowStart: row}}
      >
        <Card.Header>
          <span className='shift-title'>
            {shiftDefinition.name} ({roster.subrosterShifts(shiftDefinition.included).nStaff()})
          </span>
        </Card.Header>
        <Card.Body className='p-1'>
          <div className='shift-name-container'>
            {roster.namesSorted(config).map( name => {
              return (
                <NamePlate 
                  name={name}
                  config={config}
                  nameMapping={nameMapping}
                  onNameMappingChange={onNameMappingChange}
                  onDeleteShift={handleDeleteShift}
                  icons={roster.staffIcons(name, config)}
                  key={name}
                />
              )})}
          </div>
        </Card.Body>
        <Card.Footer>
          <Button variant='primary' className="me-2" onClick={()=>{setShowModal(true)}}>
            <FontAwesomeIcon icon='user-plus'/>
          </Button>
          <Button variant='danger' className="me-2" onClick={handleDeleteAll}>
            <FontAwesomeIcon icon='trash'/>
          </Button>
        </Card.Footer>
      </Card>
    </>

  );
}

function NamePlate({name, config, nameMapping, onNameMappingChange, onDeleteShift, icons}) {

  let [showModal, setShowModal] = useState(false);
  let nameInputRef = useRef(null);

  function handleCancel(){
    console.log("Canceling");
    setShowModal(false);
  }

  function handleSave(){
    console.log("Saving");
    setShowModal(false);
    onNameMappingChange(name, nameInputRef.current.value);
  }

  function handleKeyUp(event) {
    if (event.key === 'Enter') {
      handleSave();
    }
  }

  function handleDeleteShift(e) {
    e.stopPropagation()
    onDeleteShift(name);
  }

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {name}
    </Tooltip>
  );

  return (
    <>
      <Modal show={showModal} onHide={handleCancel}>
        <Modal.Header closeButton>
          Neuer Anzeigename für {name}
        </Modal.Header>
        <Modal.Body>
          <input 
            autoFocus
            type='text'
            ref={nameInputRef} 
            defaultValue={nameMapping.map(name)}
            onFocus={e => e.target.select()}
            onKeyUp={handleKeyUp}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel}>
            Abbrechen
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Speichern
          </Button>
        </Modal.Footer>
      </Modal>
      <OverlayTrigger
        placement="top"
        delay={{ show: 50, hide: 50 }}
        overlay={renderTooltip}
      >
        <div className='name-plate' onClick={() => {setShowModal(true)}}>
          <p className='short-name'>
            {icons.map( (icon, n) => {
              return (<TagIcon icon={icon} key={n}/>);
            })}
            {nameMapping.map(name)}
          <span className='inline-badge inline-delete' onClick={handleDeleteShift}>
            <FontAwesomeIcon size='xs' icon='trash'/>
          </span>
          </p>
        </div>
      </OverlayTrigger>
    </>
  )
}

function Navigation() {
  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand><img src='logo.svg' alt='Schmierplan' className='brand-logo'/></Navbar.Brand>
        <Navbar.Brand><NavLink className={({ isActive }) => (isActive ? 'nav-link-active' : 'nav-link-inactive')} to='/'>Wochenplan<br/><p className='text-muted' style={{fontSize: '6px', textAlign: 'center'}}>(the plan formerly known as Schmierplan)</p></NavLink></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-3">
            <NavLink className={({ isActive }) => (isActive ? 'nav-link-active' : 'nav-link-inactive')} to='/names'>
                Namenszuordnung
            </NavLink>
          </Nav>
          <Nav className="me-3">
            <NavLink className={({ isActive }) => (isActive ? 'nav-link-active' : 'nav-link-inactive')} to='/settings'>
                Einstellungen
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

function ImportResultSuccess({result}) {
  const dates = result.roster.dates();
  const start = dates[0];
  const end = dates.slice(-1)[0];

  return (
    <div>
      <h3>
        Import erfolgreich!
      </h3>
      <div className='m-3'>
        Zeitraum: {start.format('DD.MM.YY')} - {end.format('DD.MM.YY')}
      </div>
      {result.unknownCategories.length>0?
        <div className='import-warning'>
          <h4>Unbekannte Personalkategorien</h4>
          <p>
            Die folgenden Personalkategorien wurden im importierten Plan gefunden, sind aber in den Einstellungen nicht definiert und werden deshalb ignoriert. Um diese Warnung abzustellen, definiere diese Personalkategorien in den Einstellungen und setze sie bei Bedarf auf ignorieren.
          </p>
          {result.unknownCategories.map( category => {
            return (<Badge className='unknown-category-badge' key={category}>{category}</Badge>);
          })}
        </div>
        :null
      }
      {result.unknownShifts.length>0?
        <div className='import-warning'>
          <h4>Unbekannte Schichttypen</h4>
          <p>
            Die folgenden Schichten wurden im importierten Plan gefunden, sind aber in den Einstellungen nicht definiert und werden deshalb ignoriert. Um diese Warnung abzustellen, füge diese Schichten in den Einstellungen entweder in einen Schichtcontainer oder in die zu ignorierenden Schichten ein.
          </p>
          {result.unknownShifts.map( shift => {
            return (<Badge className='unknown-category-badge' key={shift}>{shift}</Badge>);
          })}
        </div>
        :null
      }
        <Button as='a' 
          className='link-to-file m-3' 
          href={URL.createObjectURL(new Blob([result.clipboard]), 'text/html')
          }
          download={`Zwischenablage-${dayjs().format('YYYY-MM-DD_HH-mm')}.html`}
        >
            <FontAwesomeIcon icon='download'/>
            &nbsp; Zwischenablage herunterladen
      </Button>
    </div>
  );
}

function ImportResultFailure({result}) {
  return (
    <div>
      <h3>
        Import fehlgeschlagen!
      </h3>
      <p>
        Wenn sich tatsächlich ein gültiger PEP-Export in der Zwischenablage befunden hat, lade bitte den Inhalt der Zwischenablage hier herunter und sende ihn an deinen Admin.
      </p>
      <Button as='a' 
          className='link-to-file' 
          href={URL.createObjectURL(new Blob([result.clipboard]), 'text/html')
          }
          download={`Zwischenablage-${dayjs().format('YYYY-MM-DD_HH-mm')}.html`}
        >
            <FontAwesomeIcon icon='download'/>
            &nbsp; Zwischenablage herunterladen
      </Button>
    </div>
  );
}

function ImportResult({result}) {
  if (result.success) {
    return (<ImportResultSuccess result={result}/>);
  } else {
    return (<ImportResultFailure result={result}/>);
  }
}

function ImportButton({config, onImportRoster}) {
  
  const [showModal, setShowModal] = useState(false)
  const [roster, setRoster] = useState(new Roster({}, {}));
  const [importResult, setImportResult] = useState(null);

  function handleShowModal(){
    setImportResult(null);
    setShowModal(true);
  }

  function handleSave(){
    setShowModal(false);
    onImportRoster(roster);
  }

  function handleCancel(){
    setShowModal(false);
  }

  function handlePaste(event){
      
    let cb = '';
      
      try {
        // get clipboard content
        console.log("Clipboard-Event", event);
        cb  = event.clipboardData.getData("text/html");
        if (cb === '') {
          cb = event.clipboardData.getData("text");
        }

        // parse content
        const parsedRoster = parseClipboard(cb);
        
        // remove all ignored and non-existant staff categories
        // TODO: really at this point ???
        // const result = parsedRoster.subrosterStaffCategories(config.config.staffCategories);
        const result = parsedRoster;
        
        // calculate unknown staff categories
        let unknownCategories = [];
        Object.keys(parsedRoster.staff).forEach( staffName => {
          const staffCategory = parsedRoster.categories[staffName]
          if(config.config.staffCategories[staffCategory] === undefined) 
          unknownCategories.push(staffCategory)
        })
        unknownCategories = [...new Set(unknownCategories)];

        // calculate unknown shifts
        const allShifts = config.allShifts();
        const allOccuringShifts = parsedRoster.allOccuringShifts();
        const unknownShifts = allOccuringShifts.filter( shift => {
          return allShifts.indexOf(shift) < 0;
        });

        setImportResult({
          success: true,
          roster: result,
          unknownCategories: unknownCategories,
          unknownShifts: unknownShifts,
          clipboard: cb,
        });
        setRoster(result);
        console.log(result);
      } catch (error) {
        console.log(error);
        setImportResult({success: false, clipboard: cb});
      }

  }

  return (
    <>
      <Modal 
        show={showModal}
        onHide={handleCancel} 
        onPaste={handlePaste}
      >
        <Modal.Header closeButton>
          Plan aus Zwischenablage importieren
        </Modal.Header>
        <Modal.Body>
          {importResult===null?
            <div>
              Strg+v drücken um Plan zu importieren
            </div>
          :
            <ImportResult result={importResult}/>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button 
            variant="secondary" 
            onClick={handleCancel}
            autoFocus 
          >
            Abbrechen
          </Button>
          <Button 
            variant="primary" 
            onClick={handleSave}
            disabled={importResult===null?true:!importResult.success}
          >
            Übernehmen
          </Button>
        </Modal.Footer>
      </Modal>
      <Button 
        className='import-button'
        onClick={handleShowModal}
      >
        <FontAwesomeIcon size='lg' icon='paste'/>
        &nbsp;
        Plan importieren
      </Button>
    </>
  );
}

function RosterDisplay({roster, selectedWeek, onSelectWeek, config, nameMapping, onNameMappingChange, onAddShift, onDeleteShift, onImportRoster}) {


  return (
    <>
      <div className='main-container text-center'>
      <div>
          <ImportButton 
            config={config}
            onImportRoster={onImportRoster}
          />
      </div>
      <IsoWeekSelection 
        weeks={roster.isoWeeks()}
        onSelect={onSelectWeek}
        selectedWeek={selectedWeek}
      />
      {
        selectedWeek?
          <WeeklyRoster
            week={selectedWeek}
            roster={roster.subrosterWeek(selectedWeek)}
            names={roster.names()}
            config={config}
            nameMapping={nameMapping}
            onNameMappingChange={onNameMappingChange}
            onAddShift={onAddShift}
            onDeleteShift={onDeleteShift}
          />
        :null
      }
      </div>
    </>
  );
}

function App(){
  let [nameMapping, setNameMapping] = useState(new NameMapping({}));
  let [config, setConfig] = useState(new Config());
  let [roster, setRoster] = useState(new Roster());
  let [selectedWeek, setSelectedWeek] = useState(null);

  function handleNameMappingChange(name, mappedName) {
    console.log(name, mappedName)
    setNameMapping(nameMapping.addMapping(name, mappedName));
  }

  function handleNameMappingDelete(name) {
    setNameMapping(nameMapping.deleteMapping(name));
  }

  function handleAddShift(employee, date, shift) {
    setRoster(roster.addShift(employee, date, shift))
  }

  function handleDeleteShift(employee, date) {
    console.log("Deleting", employee, date.format("YYYY-MM-DD"))
    setRoster(roster.deleteShift(employee, date));
    console.log(roster)
  }

  function handleNameMappingDeleteAll() {
    console.log('Deleting all name mappings.')
    setNameMapping(nameMapping.deleteAll());
  }

  function handleImportRoster(importedRoster) {
    setRoster(roster.merge(importedRoster));
    setSelectedWeek(null);
  }

  function handleSetStaffCategoriesFromArray(arr) {
    console.log("Setting Staffcats from Array", JSON.stringify(arr));
    setConfig(config.setStaffCategoriesFromArray(arr))
  }

  function handleIgnoredShiftsAdd(shift) {
    setConfig(config.addIgnoredShift(shift));
  }

  function handleIgnoredShiftsDelete(shift) {
    setConfig(config.deleteIgnoredShift(shift));
  }

  function handleTrunksSet(trunks) {
    setConfig(config.setTrunks(trunks))
  }

  function handleRestoreDefaults() {
    setConfig(config.restoreDefaults());
  }

  function handleIconMapsChange(iconMaps) {
    setConfig(config.changeIconMaps(iconMaps));
  }

  return (
    <>
      <BrowserRouter>
        <Navigation/>
        <Routes>
          <Route exact path='/' element={
            <RosterDisplay 
              roster={roster.subrosterStaffCategories(config.config.staffCategories)}
              selectedWeek={selectedWeek}
              onSelectWeek={setSelectedWeek}
              config={config}
              nameMapping={nameMapping}
              onNameMappingChange={handleNameMappingChange}
              onAddShift={handleAddShift}
              onDeleteShift={handleDeleteShift}
              onImportRoster={handleImportRoster}
            />
          }/>
          <Route exact path='/names' element={
            <NameSettings 
              nameMapping={nameMapping}
              onNameMappingDelete={handleNameMappingDelete}
              onNameMappingChange={handleNameMappingChange}
              onNameMappingDeleteAll={handleNameMappingDeleteAll}
            />} 
          />
          <Route exact path='/settings' element={
            <GeneralSettings 
              config={config}
              onSetStaffCategoriesFromArray={handleSetStaffCategoriesFromArray}
              onIgnoredShiftsAdd={handleIgnoredShiftsAdd}
              onIgnoredShiftsDelete={handleIgnoredShiftsDelete}
              onTrunksSet={handleTrunksSet}
              onRestoreDefaults={handleRestoreDefaults}
              onIconMapsChange={handleIconMapsChange}
            />} 
          />       
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
