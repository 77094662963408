import dayjs from 'dayjs';
import Roster from './Roster';

function dateRange(start, end) {
    let dates = [];
    let currentDate = start;
    while(currentDate.isBefore(end) || currentDate.isSame(end)){
        dates.push(currentDate);
        currentDate = currentDate.add(1, 'day');
    }
    return dates;
}

function getEasterSunday(year) {
	var f = Math.floor;
    
    // Golden Number - 1
	const G = year % 19;
	const C = f(year / 100);
    // related to Epact
	const H = (C - f(C / 4) - f((8 * C + 13)/25) + 19 * G + 15) % 30;
    // number of days from 21 March to the Paschal full moon
    const I = H - f(H/28) * (1 - f(29/(H + 1)) * f((21-G)/11));
    // weekday for the Paschal full moon
    const J = (year + f(year / 4) + I + 2 - C + f(C / 4)) % 7;
    // number of days from 21 March to the Sunday on or before the Paschal full moon
    const L = I - J;
    const month = 3 + f((L + 40)/44);
    const day = L + 28 - 31 * f(month / 4);

    return dayjs().year(year).month(month-1).date(day);
}

function getHolidays(year) {
    const easterSunday = getEasterSunday(year);
    return [
        {
            name: 'Neujahr',
            date: dayjs().year(year).month(0).date(1),
            full: true,
        },
        {
            name: 'Internationaler Frauentag',
            date: dayjs().year(year).month(2).date(8),
            full: true,
        },
        {
            name: 'Karfreitag',
            date: easterSunday.subtract(2, 'day'),
            full: true,
        },
        {
            name: 'Ostersonntag',
            date: easterSunday,
            full: true,
        },
        {
            name: 'Ostermontag',
            date: easterSunday.add(1, 'day'),
            full: true,
        },
        {
            name: 'Tag der Arbeit',
            date: dayjs().year(year).month(4).date(1),
            full: true,
        },
        {
            name: 'Christi Himmelfahrt',
            date: easterSunday.add(39, 'day'),
            full: true,
        },
        {
            name: 'Pfingstsonntag',
            date: easterSunday.add(49, 'day'),
            full: true,
        },
        {
            name: 'Pfingstmontag',
            date: easterSunday.add(50, 'day'),
            full: true,
        },
        {
            name: 'Tag der Deutschen Einheit',
            date: dayjs().year(year).month(9).date(3),
            full: true,
        },
        {
            name: 'Heiligabend',
            date: dayjs().year(year).month(11).date(24),
            full: false,
        },
        {
            name: '1. Weihnachtsfeiertag',
            date: dayjs().year(year).month(11).date(25),
            full: true,
        },
        {
            name: '2. Weihnachtsfeiertag',
            date: dayjs().year(year).month(11).date(26),
            full: true
        },
        {
            name: 'Silvester',
            date: dayjs().year(year).month(11).date(31),
            full: false,
        },
    ]
}

function parseClipboard(clipboard) {
    // parse html
    const parser = new DOMParser();
    const doc = parser.parseFromString(clipboard, 'text/html');

    // store start/end dates
    let start = dayjs(null);
    let end = dayjs(null);

    // store staff and their category
    let staff = {};

    // create new Roster
    let roster = new Roster();

    // extract date
    const title = doc.getElementsByClassName('WhiteSpaceTitle')[0].innerHTML;
    const value = title.matchAll(/.*– (?<month>[a-zA-ZÀ-ž]+) (?<year>\d*)/g).next().value;
    console.log(title);
    // check if we have gotten a whole month
    if(value) {

        // get capture groups
        const month = value.groups.month;
        const year = value.groups.year;
        console.log("Detected month, year: ", month, year);

        start = dayjs(`01 ${month} ${year}`, 'DD MMMM YYYY');
        end = start.endOf('month');
    
    // ... check if we've gotten a date range
    } else {
        const value = title.matchAll(/.*– (?<start>\d{2}\.\d{2}\.\d{4})-(?<end>\d{2}\.\d{2}.\d{4})/g).next().value;

        // .. if we have a match, a date range has been pasted
        if(value) {
            // get capture groups, construct start/end date
            start = dayjs(value.groups.start, "DD.MM.YYYY");
            end = dayjs(value.groups.end, "DD.MM.YYYY");

            console.log("Detected range: ", start.format("DD.MM.YYYY"), end.format("DD.MM.YYYY"));

            
        } else {
            throw TypeError("Couldn't parse date range.");
        }
    }
    
    // construct dates
    let dates = dateRange(start, end);
    console.log(dates);

    // at this point we have successfuly
    // infered the date range from the clipboard

    // parse table rows
    const rows = [...doc.getElementsByTagName("tr")];

    // start with empty section title, to skip first part of table
    let sectionTitle = "";

    // iterate over all rows
    for (const n in rows) {

        // get row
        const row = rows[n];
        
        // check if row is a title row
        const titleColumns = row.getElementsByClassName('SectionTitleLR');
        if (titleColumns.length > 0) {
            
            // set new section title for section we're currently in
            sectionTitle =  titleColumns[0].innerHTML;
            console.log(`Found new section title ${sectionTitle}.`);
            
            // stop processing row
            continue;
        }

        // check whether we're in the staff rows yet
        if (sectionTitle === "") {
            // ... not yet, don't process this row
            console.log("Not in staff section yet. Skipping row.");
            continue;
        }

        // check whether we're at the end of the table
        const planEndColumns = row.getElementsByClassName('PlanEnd');
        if (planEndColumns.length > 0) {

            // stop processing all rows
            console.log("Reached end of plan.");
            break;
        }

        // check whether we're in a lower row
        const lowerFieldColumns = row.getElementsByClassName('LowerFieldValueLR');
        if (lowerFieldColumns.length > 0) {

            // ... not interested in lower rows, skipping
            console.log("Found lower row. Skipping");
            continue;
        }

        // at this point we can be sure, that we are in staff row

        // get name
        const name = row.getElementsByClassName('UpperFieldValueLR')[0].innerHTML;
        console.log(`Found row for ${name}`)

        // get shift cells
        const shiftCells = [...row.querySelectorAll('.UpperPlanCellNormal,.UpperPlanCellWeekend')]

        // number of shift cells should equal the number of dates
        // console.assert(shiftCells.length === dates.length);

        // store all shifts for this staff member
        staff[name] = sectionTitle;

        // add shifts
        for (const [n, cell] of shiftCells.entries()) {

            // stop reading cells if we've read one for every date
            if(n === dates.length) {
                break;
            }

            // skip cell if it has a background color set
            if (cell.style.backgroundColor !== "") {
                console.log(`Found inactive day for ${name}. Skipping.`);
                continue;
            }
            
            // skip cells with no value
            if(cell.innerHTML === "&nbsp;" || cell.innerHTML === '--') {
                console.log(`Found empty day for ${name}. Skipping.`);
                continue;
            }

            /// ... else add shift to roster
            roster = roster.addShift(name, dates[n], cell.innerHTML);
            
        }

    }
    roster.categories = staff;

    console.log(roster);

    return roster;
}

export {parseClipboard, dateRange, getHolidays};